import { bindTrigger, usePopupState } from 'material-ui-popup-state/hooks'

import { Button, Typography, useMediaQuery } from '@mui/material'

import Avatar from 'components/UI/Avatar'
import DropdownButton from 'components/UI/Button/Dropdown'
import Icon from 'components/UI/Icon'

import { isWorker } from 'utils/auth'

import { useUser } from '../UserContext/useUser'
import useUserMenuOptions from './helpers'

const UserButton = () => {
  const { user, company } = useUser()
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('tablet'))
  const popupState = usePopupState({
    variant: 'popover',
    popupId: 'userMenu',
  })
  const closePopup = () => popupState.close()

  const userName = user?.name || 'Usuario'
  const companyName = company?.name ? ` - ${company?.name || 'Empresa'}` : ''
  const userHashCompanyName = `${userName}${companyName}`

  const userHashMobile = () => {
    const arrayName = userHashCompanyName.split('')

    const formatedName =
      arrayName.length > 15
        ? `${arrayName.slice(0, 15).join('')}...`
        : userHashCompanyName

    return formatedName
  }

  const options = useUserMenuOptions(closePopup, isMobile, userHashMobile)
  const openMenu = popupState?.isOpen

  const IconButton = <Avatar src={user.picture} />

  return (
    <DropdownButton
      popupState={popupState}
      variant="text"
      startIcon={IconButton}
      endIcon={
        <Icon
          name="chevron-down"
          sx={{
            transform: 'rotate(0deg)',
            transition: 'transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
            ...(openMenu && { transform: 'rotate(180deg)' }),
          }}
        />
      }
      sx={(theme) => ({
        color: theme.palette.black.dark,
        maxWidth: '19rem',
        backgroundColor: theme.palette.white.main,
        boxShadow: 'none',
        transform: 'none',
        [theme.breakpoints.down('laptop')]: {
          ...(isWorker() && {
            backgroundColor: theme.palette.primary.dark,
            color: theme.palette.white.main,
          }),
        },
        '&:hover': {
          backgroundColor: 'transparent',
          boxShadow: 'none',
          transform: 'none',
          color: theme.palette.black.main,
        },
        '&:focus': {
          backgroundColor: 'transparent',
          boxShadow: 'none',
          transform: 'none',
          color: theme.palette.primary.dark,
          [theme.breakpoints.down('laptop')]: {
            ...(isWorker() && {
              color: theme.palette.white.main,
            }),
          },
        },
        '&:active': {
          backgroundColor: 'transparent',
          boxShadow: 'none',
          transform: 'none',
          color: theme.palette.primary.dark,
        },
        padding: 0,
      })}
      title={
        <Typography variant="body1" color="inherit" noWrap>
          {userHashCompanyName}
        </Typography>
      }
      data-cy="header-user-button"
      options={options}
      iconButton={isMobile}
      Icon={
        isMobile ? (
          <Button
            {...bindTrigger(popupState)}
            variant="text"
            data-cy="header-user-button"
            sx={(theme) => ({
              minWidth: 'auto',
              '&:hover': {
                backgroundColor: 'transparent',
                boxShadow: 'none',
                transform: 'none',
                color: theme.palette.black.main,
              },
              '&:focus': {
                backgroundColor: 'transparent',
                boxShadow: 'none',
                transform: 'none',
                color: theme.palette.primary.dark,
              },
              '&:active': {
                backgroundColor: 'transparent',
                boxShadow: 'none',
                transform: 'none',
                color: theme.palette.primary.dark,
              },
              padding: 0,
            })}
          >
            {IconButton}
          </Button>
        ) : undefined
      }
    />
  )
}

export default UserButton
