import { Link } from 'react-router-dom'

import { BottomNavigationAction, alpha } from '@mui/material'

import { WORKER_PROFILE_FINANCIAL_WELLNESS } from 'config/routes'

const FINANCIAL_ROUTES = [WORKER_PROFILE_FINANCIAL_WELLNESS()]

export function BottomNavigationOption({ label, value, icon, to, ...props }) {
  return (
    <BottomNavigationAction
      component={Link}
      label={label}
      value={value}
      icon={icon}
      to={to}
      sx={({ spacing, palette, typography }) => ({
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '0.5rem',
        padding: spacing(1),
        borderRadius: '6.25rem',
        transition: 'background-color 0.3s ease-in-out',
        '&.Mui-selected': {
          minWidth: 'fit-content',
          paddingX: spacing(3),
          backgroundColor: alpha(palette.primary.main, 0.2),
          ...typography.lead2,
        },
        '& .MuiBottomNavigationAction-label': {
          display: 'none',
          whiteSpace: 'nowrap',
        },
        '&.Mui-selected .MuiBottomNavigationAction-label': {
          display: 'inline-block',
          transition: 'all 0.25s ease-out',
          position: 'static',
          maxHeight: '2rem',
        },
      })}
      {...props}
    />
  )
}

export const getBottomMenuValue = (pathname) => {
  if (FINANCIAL_ROUTES.includes(pathname)) {
    return 'financial_wellness'
  }

  return 'profile'
}
