import { useUser } from 'components/App/UserContext/useUser'
import useWorker from 'components/Worker/useWorker'

import { hasOneCompany } from 'utils/company'

import * as routes from 'config/routes'

import useCloseDrawer from '../../useCloseDrawer'

export default function useWorkerOptions() {
  const { active, clearUser } = useUser()
  const { worker } = useWorker({ useCache: true })
  const handleCloseMobileDrawer = useCloseDrawer()

  const hideWorkerFinancialWellnessOption =
    worker.company_access !== 'premium_full'

  return [
    {
      key: 'informations',
      icon: 'circle-user',
      text: 'Mi perfil',
      to: routes.WORKER_PROFILE_SHOW(),
      hidden: !active,
      onClick: () => handleCloseMobileDrawer(),
      pathsToHighlight: [
        routes.WORKER_PROFILE_PAYMENTS_HISTORY(),
        routes.WORKER_PROFILE_SHOW(),
      ],
    },
    {
      key: 'financial_wellness',
      text: 'Bienestar financiero',
      icon: 'briefcase-business',
      to: routes.WORKER_PROFILE_FINANCIAL_WELLNESS(),
      hidden: hideWorkerFinancialWellnessOption,
      badge: 'Beta',
      onClick: () => handleCloseMobileDrawer(),
    },
    {
      key: 'companies',
      icon: 'layout-grid',
      text: 'Empresas',
      to: routes.COMPANY_INDEX(),
      hidden: hasOneCompany(),
      onClick: () => {
        clearUser()
        handleCloseMobileDrawer()
      },
    },
  ]
}
