import { createContext, useMemo } from 'react'

import { Box, Drawer, useMediaQuery } from '@mui/material'

import { isWorker } from 'utils/auth'

import SidebarContent from './Content'

export const SidebarContext = createContext()

const AppSidebar = ({
  mobileOpen,
  handleDrawerToggle,
  openUpdatePaymentMethodInfoModal,
}) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('laptop'))
  const contextValue = useMemo(
    () => ({
      handleDrawerToggle,
      mobileOpen,
    }),
    [handleDrawerToggle, mobileOpen]
  )

  const container =
    window !== undefined ? () => window.document.body : undefined

  const renderSidebarMenu = () => {
    if (isMobile) {
      if (isWorker()) {
        return null
      }

      return (
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          PaperProps={{
            elevation: 0,
          }}
          sx={(theme) => ({
            marginTop: theme.mobileAppbar,
            '& .MuiBackdrop-root': {
              top: theme.mobileAppbar,
            },
            '& .MuiPaper-root': {
              width: '100%',
              marginTop: theme.mobileAppbar,
              height: `calc(100% - ${theme.mobileAppbar})`,
              backgroundColor: theme.palette.white.main,
              boxShadow: theme.shadows[7],
            },
          })}
        >
          <SidebarContent
            openUpdatePaymentMethodInfoModal={openUpdatePaymentMethodInfoModal}
          />
        </Drawer>
      )
    }

    return (
      <Box
        sx={(theme) => ({
          height: '100%',
          width: '16rem',
          backgroundColor: theme.palette.white.main,
          boxShadow: theme.shadows[7],
          zIndex: '1',
          overflow: 'hidden',
        })}
      >
        <SidebarContent
          openUpdatePaymentMethodInfoModal={openUpdatePaymentMethodInfoModal}
        />
      </Box>
    )
  }

  return (
    <SidebarContext.Provider value={contextValue}>
      {renderSidebarMenu()}
    </SidebarContext.Provider>
  )
}

export default AppSidebar
