import { useLocation } from 'react-router-dom'

import { BottomNavigation, useMediaQuery } from '@mui/material'

import Icon from 'components/UI/Icon'
import useWorker from 'components/Worker/useWorker'

import {
  WORKER_PROFILE_FINANCIAL_WELLNESS,
  WORKER_PROFILE_SHOW,
} from 'config/routes'

import { BottomNavigationOption, getBottomMenuValue } from './helpers'

export default function BottomNavigationMenu() {
  const location = useLocation()
  const { worker } = useWorker({ useCache: true })
  const isTabletUp = useMediaQuery(({ breakpoints }) =>
    breakpoints.up('tablet')
  )

  const bottomMenuValue = getBottomMenuValue(location.pathname)
  const hideWorkerFinancialWellnessOption =
    worker.company_access !== 'premium_full'

  return (
    <BottomNavigation
      value={bottomMenuValue}
      sx={({ spacing }) => ({
        width: '100%',
        minHeight: '3.9rem',
        justifyContent: 'space-around',
        alignItems: 'center',
        boxShadow:
          '0px -2px 2px rgba(40, 41, 61, 0.08), 0px -4px 4px rgba(96, 97, 112, 0.16)',
        padding: spacing(1.5),
        '& .MuiBottomNavigationAction-root': { minWidth: 'auto' },
      })}
    >
      <BottomNavigationOption
        label="Perfil"
        value="profile"
        aria-label="Ir a mi perfil"
        to={WORKER_PROFILE_SHOW()}
        icon={<Icon name="users" sx={{ fontSize: '1.5rem' }} />}
      />

      {!hideWorkerFinancialWellnessOption ? (
        <BottomNavigationOption
          label={isTabletUp ? 'Bienestar financiero' : 'Bienestar'}
          value="financial_wellness"
          aria-label="Ir a mi bienestar financiero"
          to={WORKER_PROFILE_FINANCIAL_WELLNESS()}
          icon={<Icon name="briefcase-business" sx={{ fontSize: '1.5rem' }} />}
        />
      ) : null}
    </BottomNavigation>
  )
}
