import { AppBar, IconButton, Toolbar } from '@mui/material'

import Icon from 'components/UI/Icon'

import MonogramLogo from './MonogramLogo'

const AppMobileAppBar = ({ mobileOpen, handleDrawerToggle }) => {
  return (
    <AppBar
      component="div"
      position="fixed"
      sx={(theme) => ({
        boxShadow: 'none',
        minHeight: theme.mobileAppbar,
        zIndex: `${theme.zIndex.appBar + 1}`,
      })}
    >
      <Toolbar
        sx={(theme) => ({
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: theme.spacing(0, 2),
          minHeight: theme.mobileAppbar,
          backgroundColor: theme.palette.primary.main,
        })}
        variant="dense"
        disableGutters
      >
        <MonogramLogo />
        <IconButton
          aria-label="abrir barra de navegación"
          onClick={handleDrawerToggle}
          size="large"
          sx={(theme) => ({
            color: theme.palette.primary.contrastText,
          })}
        >
          {mobileOpen ? <Icon name="x" /> : <Icon name="menu" />}
        </IconButton>
      </Toolbar>
    </AppBar>
  )
}

export default AppMobileAppBar
