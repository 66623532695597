import { AppBar, Box, useMediaQuery } from '@mui/material'

import { isWorker } from 'utils/auth'
import { hasSelectedCompany } from 'utils/company'

import SidebarLogo from '../Sidebar/SidebarLogo'
import Breadcrumbs from './Breadcrumbs/Index'
import { useBreadcrumbs } from './Breadcrumbs/helpers'
import NotificationsPopover from './CourierNotifications'
import HeadwayButton from './HeadwayButton'
import HelpButton from './HelpButton'
import UserButton from './UserButton'

const Header = () => {
  const breadcrumbs = useBreadcrumbs()
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('laptop'))

  const showHeaderButton = hasSelectedCompany() && !isWorker()

  return (
    <AppBar
      sx={(theme) => ({
        position: 'static',
        backgroundColor: theme.palette.white.main,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        boxShadow: 'none',
        height: theme.mobileAppbar,
        padding: theme.spacing(0, 2.5, 0, 2),
        ...(isWorker() && {
          backgroundColor: theme.palette.primary.dark,
        }),
        [theme.breakpoints.up('laptop')]: {
          height: theme.headerHeight,
          backgroundColor: theme.palette.white.main,
          padding: theme.spacing(0, 5, 0, 4),
        },
        [theme.breakpoints.down('sm')]: {
          ...(!(breadcrumbs.length > 1) && {
            justifyContent: 'flex-end',
            ...(isWorker() && {
              justifyContent: 'space-between',
            }),
          }),
        },
      })}
    >
      {isWorker() && isMobile ? <SidebarLogo /> : <Breadcrumbs />}
      <Box
        sx={(theme) => ({
          display: 'grid',
          gridAutoFlow: 'column',
          alignItems: 'center',
          columnGap: theme.spacing(2),
        })}
      >
        <Box
          sx={(theme) => ({
            display: 'grid',
            gridAutoFlow: 'column',
            alignItems: 'center',
            columnGap: theme.spacing(2),
          })}
        >
          <HelpButton />
          {showHeaderButton ? <HeadwayButton /> : null}
          {showHeaderButton ? <NotificationsPopover /> : null}
        </Box>
        <UserButton />
      </Box>
    </AppBar>
  )
}

export default Header
